import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../css/global.css";
import { motion } from "framer-motion";
import styled from "styled-components";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import AnimatedLogo from "../components/animated-logo/animated-logo";
import {
  mainBlack,
  mainWhite,
  screen,
  whileInVew,
} from "../components/variables/variables";
import Obfuscate from "react-obfuscate";

const Wrapper = styled.div`
  .intro {
    padding: 90px 0 45px 0;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 180px 0 90px 0;
    }

    .col {
      max-width: 900px;

      .description {
        margin: 16px 0 0 0;
        @media ${screen.xsmall} {
          margin: 21px 0 0 0;
        }

        h2 {
          font-size: 1.2rem;
          font-weight: 700;
          margin: 38px 0 16px 0;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
            margin: 52px 0 21px 0;
          }
        }

        strong {
          font-weight: 500;
        }

        a {
          color: ${mainBlack};
        }
      }
    }
  }

  .featured {
    padding: 45px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .item-list {
      @media ${screen.xsmall} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
      }

      &__each {
        border-radius: 12px;
        box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
        padding: 26px;
        margin: 0 0 26px 0;
        @media ${screen.xsmall} {
          padding: 36px;
          width: calc(50% - 30px);
          margin: 0 15px 30px 15px;
        }
        @media ${screen.small} {
          width: calc(33.33% - 30px);
        }

        img {
          border-radius: 9px;
        }

        .subheading {
          font-weight: 700;
          font-size: 1rem;
          margin: 17px 0 10px 0;
          @media ${screen.xsmall} {
            font-size: 1.1rem;
            margin: 22px 0 10px 0;
          }
        }
      }
    }
  }

  .cta {
    margin: 0 0 -115px 0;
  }

  .process {
    background-color: ${mainBlack};
    color: ${mainWhite};
    padding: 165px 0 45px 0;
    @media ${screen.xsmall} {
      padding: 205px 0 90px 0;
    }

    .top-txt {
      max-width: 700px;
      margin: 0 0 26px 0;
      @media ${screen.xsmall} {
        margin: 0 0 40px 0;
      }
    }
  }

  .people {
    background-image: ${(props) => `url(${props.peopleBackgroundImage})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 80px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }
    @media ${screen.small} {
      padding: 120px 0;
    }
    @media ${screen.medium} {
      padding: 150px 0;
    }

    .flex-row {
      @media ${screen.small} {
        display: flex;
      }

      .col {
        border-radius: 10px;

        &--left {
          background-color: ${mainWhite};
          padding: 25px 20px;
          margin: 0 0 30px 0;
          @media ${screen.small} {
            margin: 0;
            padding: 25px 30px 30px 30px;
            width: 38%;
          }
        }

        &--right {
          @media ${screen.small} {
            padding: 0 0 0 60px;
            width: 62%;
          }
        }
      }
    }
  }
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <Seo
        title={"Privacy Policy"}
        description={
          "This Privacy Policy sets out our commitment to protecting the privacy of your personal information that we collect through this website irada.com.au (Site) or directly from you."
        }
      />
      <Wrapper>
        <section className="intro">
          <InnerWrapper>
            <motion.div {...whileInVew} className="col">
              <h1 className="heading">Privacy policy</h1>
              <div className="description">
                <p>
                  <strong>Irada Pty Ltd, ABN 51 621 314 912</strong>
                </p>
                <p>
                  This Privacy Policy sets out our commitment to protecting the
                  privacy of your personal information that we collect through
                  this website irada.com.au (Site) or directly from you.
                </p>
                <p>
                  Please read this Privacy Policy carefully. Please contact us
                  if you have any questions.
                </p>
                <p>
                  You providing us with personal information indicates that you
                  have had sufficient opportunity to access this Privacy Policy
                  and that you have read and accepted it.
                </p>
                <p>
                  If you do not wish to provide personal information to us, then
                  you do not have to do so, however it may affect your use of
                  this Site or any products and services offered on it.
                </p>
                <h2>1. Type of personal information collected</h2>
                <p>
                  Personal Information: The type of personal information we
                  collect may include is set out on our website.
                </p>
                <p>
                  If we receive your personal information from third parties, we
                  will protect it as set out in this Privacy Policy.
                </p>
                <h2>2. Collection and use of personal information</h2>
                <p>
                  We collect and use the personal information for purposes
                  including to contact and communicate with you, for internal
                  record keeping and for marketing.
                </p>
                <h2>3. Disclosure of personal information</h2>
                <p>
                  We may disclose personal information for purposes including to
                  provide our products and services to you, and as required by
                  law.
                </p>
                <p>
                  Where we disclose your personal information to third parties
                  for these purposes, we will request that the third party
                  follow this Privacy Policy regarding handling of your personal
                  information.
                </p>
                <h2>4. Access to and correction of personal information</h2>
                <p>
                  Access: You may request details of personal information that
                  we hold about you, in certain circumstances set out in the
                  Privacy Act 1988 (Cth). An administrative fee may be payable
                  for the provision of information. We may refuse to provide you
                  with information that we hold about you, in certain
                  circumstances set out in the Privacy Act.
                </p>
                <p>
                  Correction: If you believe that any information we hold on you
                  is inaccurate, out of date, incomplete, irrelevant or
                  misleading, please contact us by email. We rely in part upon
                  customers advising us when their personal information changes.
                  We will respond to any request within a reasonable time. We
                  will endeavor to promptly correct any information found to be
                  inaccurate, incomplete or out of date.
                </p>
                <h2>5. Complaints about breach</h2>
                <p>
                  If you believe that we have breached the Australian Privacy
                  Principles and wish to make a complaint about that breach,
                  please contact us on the email address below.
                </p>
                <h2>6. Unsubscribe</h2>
                <p>
                  To unsubscribe from our e-mail database, or opt out of
                  communications, please contact us at the details below.
                </p>
                <h2>7. Storage and Security</h2>
                <p>
                  We are committed to ensuring that the information you provide
                  is secure.
                </p>
                <p>
                  For any questions or notice, please contact us at{" "}
                  <Obfuscate email={"info@irada.com.au"} />.
                </p>
                <p>
                  <strong>Last update:</strong> 24 April 2017
                </p>
              </div>
            </motion.div>
          </InnerWrapper>
          <AnimatedLogo />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPage;
